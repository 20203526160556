<template>
  <b-tabs
    type="is-boxed"
    :value="value"
    :animated="false"
    @input="handleChange"
  >
    <slot name="items" />
  </b-tabs>
</template>

<script>
export default {
  name: 'Tabs',

  props: {
    value: {
      type: String,
      default: undefined,
    },
  },

  methods: {
    handleChange(value) {
      this.$emit("input", value);
    },
  }
};
</script>
