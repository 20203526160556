<template>
  <div 
    ref="photoswipe"
    class="gallery-slideshow"
  >
    <a
      v-for="image in data"
      :key="image.src"
      :href="image.src"
      :data-pswp-width="image.width"
      :data-pswp-height="image.height"
    >
      <b-image
        class="gallery-slideshow-image"
        :src="image.src"
      />
    </a>
  </div>
</template>

<script>
import PhotoSwipeLightbox from "photoswipe/dist/photoswipe-lightbox.esm.js";

export default {
  name: 'ImageGallerySlideshow',

  props: {
    data: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      lightbox: null,
    };
  },

  mounted() {
    const options = {
      gallery: this.$refs.photoswipe,
      children: 'a',
      pswpModule: () => import('photoswipe'),
    };
    this.lightbox = new PhotoSwipeLightbox(options);
    this.lightbox.init();
  },

  unmounted() {
    this.lightbox.destroy();
  },
};
</script>

<style>
@import "~photoswipe/dist/photoswipe.css";
</style>
